@import url('https://fonts.googleapis.com/css?family=Lato:ital,wght@0,400;0,700;0,600;1,400;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');
:root {
  --color-primary: #E54B0E;
  --color-primary-pastel: #E65B25;
  --color-secondary: #FFF;
  --shadow-light: 0px 2px 20px rgba(0, 0, 0, 0.15);
  --shadow-dark: 0px 2px 20px rgba(0, 0, 0, 0.3);
  --color-info: #717171;
  --color-text: #404040;
  --border-light: 1px solid #DCDCDC;
  --hover-light: #DCDCDC;
  --border-upload: #e1d4cb;
  --background-upload: #fcfaf8;
}

.App {
  display: flex;
  width: 100vw;
  font-size: 1.8vh;
  color: #404040;
  font-family: 'Work Sans', 'Roboto', sans-serif;
}
.NavigationContainer {
  min-height: 100vh;
  width: 20%;
  display: flex;
  flex-direction: column;
  color: #ffffff;
  background-color: rgba(229, 75, 14, 0.8);
}
.PageContainer {
  display: flex;
  min-height: 100vh;
  width: 80%;
  justify-content: center;
}
.ReportFilterDisplay {
  flex-direction: column;
}
.Row {
  display: flex;
  flex-direction: row;
}
.ReportFilterContainer{
  padding-left: 1vw;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.ReportFilterContainerNoFlex{
  padding-left: 1vw;
  width: 90%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.FilterContainer {
  gap: 0.5vw;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}
.ReportFilterDatePicker {
  height: 2.5em;
  font-size: 0.875em;
  border: var(--border-light) !important;
  margin: 0.5em 0;
  padding: 0.25em 1em;
  width: 180px;
}
.InputLightStyle {
  transition: opacity 500ms linear;
  border: var(--border-light);
  margin: 0.5em 0;
  padding: 0.25em 1em;
  cursor: pointer;
  font-size: 0.875em;
  height: 2.5em;
}
.ReportTextInput{
  transition: opacity 500ms linear;
  border: var(--border-light);
  border-radius: 5px;
  margin: 0.5em 0;
  padding: 0.25em 1em;
  cursor: pointer;
  font-size: 0.875em;
  height: 2.5em;
}
.intercom-lightweight-app-launcher {
  background-color: blue;
  width: 50px !important;
  height: 50px !important;
}
.intercom-lightweight-app-launcher-icon{
  width: 50px !important;
  height: 50px !important;
}
@media (max-width: 700px) {
  .App {
    flex-direction: column;
  }
  .NavigationContainer{
    display: flex;
    flex-direction: row;
    min-height: 8vh;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .PageContainer {
    width: 100vw;
  }
  .intercom-lightweight-app-launcher {
    background-color: blue;
    width: 50px !important;
    height: 50px !important;
  }
  .intercom-lightweight-app-launcher-icon{
    width: 50px !important;
    height: 50px !important;
  }
}
.LoaderContainer {
  width: 80%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background: rgba(205, 205, 205, 0.834);
  z-index: 999999999999;
}

.LoaderContainer.Full {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background: rgba(205, 205, 205, 0.834);
  z-index: 999999999999;
}

.Spinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #f1853d transparent #f1973d transparent;
  border-radius: 50%;
  animation: spin-anim 1.2s linear infinite;
}

@keyframes spin-anim {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}
