.OrderPageContainer {
    font-family: 'Work Sans', 'Roboto', sans-serif;
    height: 100vh;
    overflow-y: scroll;
    padding-left: 4%;
    padding-top: 2%;
    width: 80%;
}
.StepsContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 1%;
}
.ActiveStep{
    color:var(--color-primary);
}
.RightArrow{
    padding-inline: 1.5%;
}
.Cursor {
    cursor: pointer;
}
.Disable {
    pointer-events: none;
    opacity: 0.5;
    background: #CCC;
}
/* Product page */
.SearchProductContainer{
    display: flex;
    flex-direction: row;
    width: 85%;
    justify-content: space-between;
    align-items: center;
    margin-top: 2%;
}
.SearchProductContainer p{
    padding-right: 2%;
}
.AddedProductsTable{
    width: 85%;
    margin-top: 1%;
    margin-bottom: 2%;
}

/* Address page */
.PageSubtitleContainer{
    margin-top: 1%;
    font-weight: 600;
}
.DirectionStepContainer{
    display: flex;
    flex-direction: column;
    margin-bottom: 5%;
}
.InputStyle {
    width: 26vw;
    height: 30px;
}
.InputHeight {
    min-height: 60px;
    max-height: 200px;
}
.DirectionLabel{
    margin-top: 2%;
    margin-bottom: 1%;
}
.BottomRow {
    width: 85%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 5%;
}
.BottomRow div:only-child {
    margin-left: auto;
  }
.Button {
    padding: 0.5vh 1vw;
    border-radius: 8px;
    box-shadow: var(--shadow-light);
    transition: box-shadow, 0.2s ease-out;
    text-align: center;
    box-sizing: border-box;
}
.Button.Primary{
    background-color:  var(--color-primary);
    color: var(--color-secondary);
}
.Button:hover{
    cursor: pointer;
    box-shadow: var(--shadow-dark);
    transition: box-shadow, 0.2s ease-out;
    transform: translateY(-3px);
}
.GoBackOrderPage{
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 30%;
    color: var(--color-primary);
}
/* Rates page */
.InfoTableContainer {
    display: flex;
    flex-direction: row;
    gap: 1.5vw;
    width: 85%;
    margin-bottom: 2%;
}
.Column {
    justify-content: space-between;
    gap: 1.5vw;
    flex: 1;
    margin-top: 2%;
}
.Column.RateLeft {
    flex: 1;
}
.Column.RateRight {
    flex: 1;
}
.ColumnTitle{
    margin-bottom: 2%;
    font-weight: 600;
}
.FlexRow{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.ItemsContainer{
    background-color: #FFF;
    border-radius: 8px;
    padding: 1.5vw;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 3vh;
    border: 1px solid #DCDCDC;
}
.ItemWithPrice {
    display: flex;
    flex-direction: row;
    width: 97%;
    justify-content: space-between;
}
.Item {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}
.RateDescriptionContainer{
    margin-left: 2%;
    align-items: center;
}
.RatePrice{
    display: flex;
    flex-direction: row;
    font-weight: 600;
    font-size: 80%;
}
.Title {
    color: var(--color-text);
    font-size: 80%;
}
.Label{
    color: var(--color-info);
    font-size: 80%;
}
/* Summary page */
.Column .SummaryLeft {
    flex: 1;
}
.Column .SummaryRight {
    flex: 1;
}
.AllWidth {
    width: 100%;
}
.SummaryTitle{
    text-align: left;
    width: 100%;
    font-weight: 700;
    border-bottom: var(--border-light);
    padding-bottom: 1vh;
    margin-bottom: 1vh;
}
.Item div {
    font-size: 85%;
}
.Middle .Item {
    flex-direction: row;
    justify-content: space-between;
}

.Middle .Label {
    color: var(--color-text);
}

.Middle .Total {
    color: var(--color-primary);
    font-weight: 700;
}

table {
    width: 100%;
    border-collapse: collapse;
    padding: 0;
}

table .Orange{
    color: var(--color-primary);
}

tr {
    border-bottom: var(--border-light);
}

th {
    color: var(--color-text);
    text-align: center;
    padding: 0 0 1vh 0;
}

th:nth-child(1) {
    text-align: left;
}
  
td {
    color: var(--color-text);
    text-align: center;
    padding: 1vh 0;
}

td:nth-child(1) {
text-align: left;
}

.SummaryOrderDetail .Skeleton {
    background-color: var(--color-third);
    width: 40%;
}

.SummaryOrderDetail .Column {
    gap: 0;
}

.SummaryOrderDetail .Sku {
    color: var(--color-info);
    font-size: 85%;
}