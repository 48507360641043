.Modal {
    min-width: 30%;
    padding: 1.5vw;
    border-radius: 8px;
    position: fixed; /* Ensure it's fixed position */
    top: 60%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Adjust to truly center */
    border: unset;
    background: white; /* Ensure modal has a background color */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Add shadow for better visibility */
    z-index: 1000; /* Ensure it appears on top */
}

.Modal[open] {
    display: flex;
    flex-direction: column;
    animation: open 200ms ease-out;
}

.Modal.Closing {
    animation: close 200ms ease-out;
}

.Modal .Close {
    position: absolute;
    top: 0;
    right: 0;
    height: 1.5vw;
    width: 1.5vw;
    color: var(--color-text);
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    -webkit-user-select: none;
}

.Modal .Close:hover {
    cursor: pointer;
}

@keyframes open {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes close {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

/* Filters Modal */
.Modal .TextInput {
    border-radius: 6px;
    border-width: 1px;
    width: 180px;
    border-color: rgba(161, 161, 161, 0.9);
    height: 30px;
}
.Modal .TextInput::placeholder {
    color: black;
}
.Modal .Title {
    font-size: 115%;
    font-weight: 700;
    align-self: center;
}
.Modal .Gap {
    display: grid;
    gap: 10px;
}
.Center {
    align-self: center;
    justify-content: center;
}
.Modal .Center .Button {
    cursor: pointer;
    text-align: center;
}
.Button .Secondary {
    background-color: var(--color-secondary);
    color: var(--color-primary);
}
.Modal .VerticalMargin {
    margin-top: 10px;
}

/* Additional styles for help content */
.HelpContent img {
    width: 100%;
    margin-top: 20px;
}

.HelpContent .AdditionalInfo {
    margin-top: 20px;
}

.HelpContent .AdditionalInfo h3 {
    margin-bottom: 10px;
}

.HelpContent h2,
.HelpContent h3 {
    color: black;
}

