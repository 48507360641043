.FormMainContainer {
    overflow-y: scroll;
    height: 95vh;
    width: 72vw;
    scroll-behavior: smooth;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.FormMainContainer::-webkit-scrollbar {
    display: none;
}

.Form {
    display: flex;
    flex-direction: column;
    width: 70vw;
    margin-top: 50px;
}

.FormTitle {
    font-weight: 600;
    margin-bottom: 15px;
}

.FormContainer {
    display: flex;
    flex-direction: row;
    padding-top: 20px;
    padding-bottom: 20px;
    align-items: center;
}

.PageContainerGoBack {
    display: flex;
    flex-direction: row;
    margin-bottom: 1vh;
    margin-top: 1vh;
    align-items: center;
}

.GoBackIcon {
    cursor: pointer;
}

.GoBackText {
    margin-left: 0.5vw;
    color: black;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
}

.FormHistoryCell {
    font-family: 'Work Sans', 'Roboto', sans-serif;
    font-size: '16px';
}

.FormContainerItems {
    display: flex;
    flex-direction: row;
    padding-top: 30px;
    align-items: center;
}

.FormLabel {
    margin-left: 30px;
    width: 27vw;
    min-width: 200px;
    align-items: center;
}

.ReceptionFormDownloadContainer {
    display: inline-flex;
    flex-direction: column;
}

.ReceptionFormDownloadContainer a{
    align-self: flex-start;
}

.FormLabelDisplay {
    flex-direction: row;
    display: flex;
}
.UploadTutorialText {
    color: var(--color-info);
    font-size: 80%;
    cursor: pointer;
}
.UploadItemsText {
    padding: 1vh;
    padding-bottom: 0vh;
    color: var(--color-info);
    font-size: 80%;
}

.FileUploadItemsContainer {
    width: 90%;
}

.UploadItemsTemplate {
    display: inline-block;
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;
}

.ProductAmountLabel {
    margin-right: 10px;
}

.ProductPriceLabel {
    margin-right: 10px;
}

.ProductPriceAmountContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 1.8rem;
    margin-bottom: 20px;
}

.ProductAddInfoContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 1.8rem;
    margin-bottom: 20px;
    margin-left: 30px;
}

.MoreInfo {
    border: 0;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    width: 1vw;
    height: 1vw;
    margin-left: 10px;
    cursor: pointer;
    font-weight: 600;
    font-size: 12px;
}

.MoreInfoColorWhite {
    color: white;
    border: solid 1.5px white;
    background-color: rgba(229, 75, 14, 0.7);
}

.MoreInfoColorOrange {
    color: rgba(229, 75, 14, 0.9);
    border: solid 1.5px rgba(229, 75, 14, 0.9);
    background-color: white;
}

.ExpirationDateContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 1.8rem;
}

.MarginFromCheckbox {
    margin-left: 0.5rem;
}

.InputForm {
    display: flex;
}

.AlignIcons {
    display: flex;
    align-items: center;
}

.InputFormText {
    border-radius: 6px;
    border-width: 1px;
    border-color: rgba(161, 161, 161, 0.9);
    height: 1.8rem;
}

.CellTextArea {
    outline: none;
    border: none;
    resize: none;
    min-width: 10vw;
    width: 100%;
    height: 100%;
    padding-left: 10px;
    padding-bottom: 0px;
    cursor: default;
    display: block;
}

body {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.InputProductText {
    width: 100%;
    height: 100%;
    padding-left: 15px;
    outline: none;
    border: none;
    cursor: default;
}
.FormSwitchButtonContainer{
    display: flex;
    flex-direction: row;
    padding-top: 0px;
    padding-bottom: 10px;
    align-items: center;
}
.FormSwitchUploadButtonContainer{
    display: flex;
    flex-direction: column;
    padding-top: 0px;
    padding-bottom: 10px;
    width: 32%;
}
.CheckboxContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 1.8rem;
}

.DeleteIcon{
    justify-content: center;
    display: flex;
}

.ViewIcon{
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

input[type=checkbox] {
    position: relative;
    cursor: pointer;
}

input[type=checkbox]:before {
    content: "";
    display: block;
    position: absolute;
    width: 16px;
    height: 16px;
    border: 1px solid #555555;
    border-radius: 3px;
    background-color: white;
}

input[type=checkbox]:checked:after {
    content: "";
    display: block;
    width: 5px;
    height: 10px;
    border: solid rgba(229, 75, 14, 0.9);
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 2px;
    left: 6px;
}

.Margins {
    margin-left: 0.5rem;
    margin-right: 1rem;
}

.ShortWidth {
    width: 125px;
}

.InputGrossValueWidth {
    width: 185px;
}

.WidthAmount {
    width: 3.5rem;
}

.Amount::-webkit-inner-spin-button, .no-spin::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
}

.Amount {
    -moz-appearance:textfield !important;
}

.TableInputDatepicker {
    width: 100%;
    height: 100%;
    padding-left: 15px;
    outline: none;
    border: none;
    cursor: text;
}

.NotAllowed {
    cursor: not-allowed;
}

.TableTextBackgroundColor {
    background-color: rgba(224, 224, 224, 0.5);
    cursor:'text';
}

.TableNumberBackgroundColor {
    background-color: rgba(224, 224, 224, 0.5);
    cursor:'text';
}

.TableDateBackgroundColor {
    background-color: rgba(224, 224, 224, 0.5);
    cursor:'text';
}

.InputFormDocumentUpload {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    border-color: var(--border-upload);
    background-color: var(--background-upload);
    border-width: 1px;
    border-style: dashed;
    padding-left: 5px;
    padding-right: 5px;
    height: 1.7rem;
    cursor: pointer;
}

.InputFormDocumentUploadText {
    margin-left: 5px;
    display: inline-block;
    width: 180px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}

.AlignCenter {
    flex-direction: row;
    display: flex;
    align-content: center;
    align-items: center;
}

.WithDatePicker {
    width: 165px;
    height: 27px;
}

.selectInput {
    width: 180px;
    height: 30px;
    display: flex;
    align-content: center;
    align-items: center;
    margin-left: 0px;
}

/*style the arrow inside the select element:*/
input.hide-clear[type=search]::-ms-clear,
input.hide-clear[type=search]::-ms-reveal {
    color:black;
}

input[type="search"]::-webkit-search-cancel-button:hover { cursor: pointer; }

select {
    border-radius: 6px;
    border-width: 1px;
    border-color: rgba(161, 161, 161, 0.9);
    height: 1.7rem;
    margin-left:15px;
}

.RadioAlignCenter {
    flex-direction: row;
    display: flex;
    align-content: center;
}

input[type='file'] {
    border-radius: 6px;
    border-width: 1px;
    border-color: rgba(161, 161, 161, 0.9);
    height: 1.7rem;
    margin-left: 20px;
}

input[type='radio'] {
    -webkit-appearance:none;
    min-width:15px;
    height:15px;
    border:1px solid var(--color-info);
    border-radius:50%;
    outline:none;
    box-shadow:0 0 5px 0px var(--shadow-light) inset;
    margin: 0;
    display: grid;
    place-content: center;
}

input[type='radio']:hover {
    box-shadow:0 0 5px 0px var(--color-primary-pastel) inset;
}

input[type='radio']:before {
    content: "";
    width:5px;
    height:5px;
    border-radius: 50%;
    box-sizing: border-box;
    transition: 120ms transform ease-in-out;
}

input[type='radio']:after {
    box-sizing: border-box;
}

input[type='radio']:checked:before {
    background:rgba(229, 75, 14, 0.9);
}

.checkbox input[type="checkbox"] {
    display: none;
}

.checkbox input[type="checkbox"]:checked + .wrapper {
    background-color:rgba(229, 75, 14, 0.9);
}

.checkbox input[type="checkbox"]:checked + .wrapper .knob {
    left: 20px;
}

.checkbox .wrapper {
    background-color: #404040;
    border-radius: 10px;
    width: 42px;
    height: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
    outline: none;
    margin-right: 0.5rem;
}

.checkbox .knob {
    background-color: white;
    border-radius: 100%;
    display: inline-block;
    margin-left: 2px;
    position: relative;
    width: 16px;
    height: 16px;
    left: 0;
    transition: left 100ms ease-in-out 0s;
}

.InputFormIcon {
    margin-right: 10px;
}

.Radio {
    display: flex;
    flex-direction: column;
}

.Select {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    margin-right: 1em;
}

.SelectInput {
    flex-grow: 1;
    cursor: pointer;
}

.SelectInputText {
    z-index: -1;
}

.AddProductButton {
    background-color: rgba(229, 75, 14, 0.9);
    border-radius: 30px;
    font-size: 1.4vh;
    width: 5vw;
    height: 3vh;
    color: white;
    display: flex;
    cursor: pointer;
    border: none;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
    transition: box-shadow, transform, 0.2s ease-out;
}

.AddProductButton:hover {
    box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.2);
    transform: translateY(-3px);
    cursor: pointer;
}

.AddedProductsHeader {
    background-color: rgba(229, 75, 14, 0.9);
}

.AmountBtn {
    background-color: rgba(229, 75, 14, 0.9);
    border-radius: 30px;
    font-size: 1.4vh;
    width: 25px;
    height: 25px;
    color: white;
    display: flex;
    cursor: pointer;
    border: none;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
    transition: box-shadow, transform, 0.2s ease-out;
}

.MarginRight {
    margin-right: 10px;
}

.MarginLeft {
    margin-left: 10px;
}

.FormFooter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1vh;
}

.FooterWarningText {
    font-weight: 600;
    margin-bottom: 1vh;
}

.AmountContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.TableExpireTitle {
    display: flex;
    align-items: center;
    justify-content: left;
}

.DeleteProduct {
    margin-left: 7px;
}

.react-datepicker__day--selected {
    background-color: rgba(229, 75, 14, 0.9);
}

.react-datepicker__day--selected:hover {
    background-color: rgba(229, 75, 14, 0.7);
}

.react-datepicker__input-container input {
    border-color: rgba(0, 0, 0, 0.3);
    border-width: 1px;
    border-radius: 5px;
}

/* Autocomplete component */
.css-1fqf3bh-MuiTableCell-root {
    text-align: center !important;
}
.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    color: rgba(229, 75, 14, 0.8) !important;
}

.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: rgba(229, 75, 14, 0.8) !important;
    border-width: 1px !important;
}

.css-1pysi21-MuiFormLabel-root-MuiInputLabel-root {
    font-family: 'Work Sans', 'Roboto', sans-serif;
    font-size: 14px !important;
    z-index: -99 !important;
}

.MuiAutocomplete-option {
    font-family: 'Work Sans', 'Roboto', sans-serif !important;
}

/* Form history css */
.FormHistoryContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    justify-content: center;
}

.FormHistoryTitle {
    font-weight: 600;
    margin-bottom: 20px;
    align-self: left;
}

.FormHistoryTitleTooltipContainer {
    display: inline-flex;
}

.FormHistoryPdfDownloadContainer {
    flex-direction: column;
    display: flex;
}

.FormHistoryTitleTooltip {
    padding-top: 4px;
}

.UpdateItemsContainer {
    display: flex;
    position: absolute;
    bottom: 5vh;
    background-color: rgba(229, 75, 14, 0.9);
    border-radius: 12px;
    padding: 1vw;
    flex-direction: row;
    color: white;
    z-index: 1;
}

.UpdateItemsBtns {
    border: 1px solid white;
    border-radius: 12px;
    font-size: 1.8vh;
    width: 12vw;
    height: 4vh;
    display: flex;
    margin-left: 1vw;
    margin-right: 1vw;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    transition: box-shadow, transform, 0.2s ease-out;
}

.UpdateItemsBtns:hover {
    box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px);
    cursor: pointer;
}

/* Filters section in deliver rates and products */
.ItemsFiltersContainer {
    display: flex;
    flex-direction: row;
    width: 90%;
    padding-bottom: 1%;
    justify-content: left;
    align-items: center;
}
.EditTutorialText {
    color: var(--color-info);
    font-size: 80%;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    width: 90%;
    padding-bottom: 1%;
    justify-content: left;
    align-items: center;
}
.ItemsFiltersBtn {
    display: inline-flex;
    font-size: 13px;
    align-items: center;
    text-align: center;
    justify-content: center;
    text-decoration: none;
    cursor: pointer;
    font-weight: 600;
    border: solid 1px #686868;
    border-radius: 2rem;
    padding: 0.1rem 0.8rem;
    min-height: 0.8vh;
    outline: none;
    min-width: 5vw;
}

.Colored {
    background-color: #E54B0E;
    color: white;
}

.ItemsFiltersBtn:hover {
    opacity: .92;
    box-shadow: 1px 4px 3px 0 rgba(0,0,0,.24);
}

.ItemsFiltersSelecInputsContainer {
    width: 80%;
}


.filterRow {
    display: flex;
    flex-wrap: wrap;
}

.filterRow > * {
    margin-right: 8px; /* Horizontal spacing between filters */
    margin-bottom: 8px; /* Vertical spacing between filters */
}

.ItemsFiltersBtnPressed {
    border: solid 1px #E54B0E;
    background-color: #E54B0E;
    color: white;
}

.ItemsBtnsContainer {
    display: inline-flex;
    margin-left: 1.5vw;
}

.ItemsBtnMargin {
    margin-left: 1vw;
}

.LargeIcon {
    font-size: 19.5px;
}

.TextInput {
    width: 190px;
    height: 1.7rem;
    display: flex;
    align-content: center;
    align-items: center;
    margin-left: 0px;
    border: solid 1px rgba(161, 161, 161, 0.9);;
    border-radius: 6px;
}

.NumericDeliveryRatesFilter {
    border-radius: 6px;
    border-width: 1px;
    width: 180px;
    border-color: rgba(161, 161, 161, 0.9);
    height: 1.7rem;
}
.NumericDeliveryRatesFilter::placeholder {
    color: black;
}