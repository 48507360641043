.LandingPageMainContainer {
    flex-direction: column;
    align-items: center;
}
.LandingPageFirstSection{
    height: 95vh;
    width: 100vw;
    display: grid;
    place-items: center;
    position: relative;
    top: 5vh;
    left: 0;
    text-align: center;
    background-color: #F1F1F1;
}
.TitleWrapper{
    text-align: center;
    padding-top: 5rem;
    max-width: 40rem;
}
.MainTitle{
    font-weight: 600;
    font-size: 50px;
    width: 100%;
    cursor: default;
    color:black;
    line-height: 60px;
    margin-bottom: 0;
}
.MainSubTitle{
    font-weight: 400;
    font-size: 16px;
    cursor: default;
}
.LandingPageMainBtn{
    display: inline-flex;
    width: 2.5rem;
    justify-content: center;
    color: white;
    text-decoration: none;
    cursor: pointer;
    font-weight: 600;
    font-size: 16px;
    background-color: #E54B0E;
    outline: none;
    min-width: 4rem;
    padding: 0.3rem 4.5rem;
    margin: 2rem 0 1.9rem 0;
    border-radius: 2rem;
    position: relative;
}
.LandingPageMainBtn:hover{
    opacity: .92;
    box-shadow: 1px 4px 3px 0 rgba(0,0,0,.24);
    background: #E54B0E radial-gradient(circle,transparent 1%,#E54B0E 0) 50%/15000%;
}
.LandingPageImgContainer{
    align-items: center;
    width: 33vw;
    max-width: 100%;
}
.LandingPageGif{
    width: 100%;
    height: auto;
    display: block;
}
/* Icons & Computer section */
.IconsComputerSection{
    display: grid;
    place-items: center;
    width: 100vw;
    height: 100vh;
    position: relative;
    top: 0;
    left: 0;
    text-align: center;
    margin-top: 20vh;
    margin-bottom: 0vh;
}
.ComputerSectionTitleWrapper{
    position: absolute;
    top: 5vh;
    max-width: 45rem;
    text-align: center;
    padding-bottom: 5vh;
}
.LineIconsImgContainer{
    position: absolute;
    align-items: center;
    width: 80vw;
    top: 25vh;
    margin-bottom: 0rem;
    align-items: center;
}
.LineIconsImg{
    width: 100%;
    height: auto;
    display: block;
}
.SeparateIconsContainer {
    display: none;
}
.TextInLineIcons{
    max-width: 20vh;
    flex-wrap: wrap;
    position: absolute;
    margin-bottom: 0;
}
.FirstIcon{
    left: 0;
    top: 65%;
}
.SecondIcon{
    left: 25%;
    top: 3%;
}
.ThirdIcon{
    left: 45%;
    top: 65%;
}
.FourthIcon{
    left: 71%;
    top: 3%;
}
.FifthIcon{
    right: 1%;
    top: 65%;
}
.ComputerImgContainer{
    align-items: center;
    width: 50rem;
    bottom: 0px;
    position: absolute;
    text-align: center; 
    overflow: hidden;
}
.ComputerImg{
    max-width: 100%;
    transform: translateY(32%);
}
/* Map microfullfilment section */
.FullfilmentSection{
    display: grid;
    place-items: center;
    width: 100vw;
    padding-top: 5vh;
    background-color: #F1F1F1;
}
.FullfilmentSectionTitleWrapper{
    display: flex;
    align-items: center;
    max-width: 60vw;
    text-align: center;
    padding-top: 0.5rem;
}
.MapImgContainer{
    z-index:0;
    align-items: center;
    width: 95vw;
    position: relative;
}
.MapImg {
    width: 100%;
    height: auto;
    display: block;
}
.TextFullfilmentSectionContainer{
    width: 45%;
    position: relative;
    display: grid;
    grid-template-columns: 0.8fr 1fr 1fr;
    text-align: left;
    box-sizing: border-box;
    left: 25%;
    bottom: 60%;
    padding-bottom: 3%;
    column-gap: 1em;
}
.MobileTextFullfilmentSection{
    display: none;
}
.TextFullfilmentSectionContainer h1{
    font-weight: 700;
    font-size: 18px;
}
.TextFullfilmentSectionContainer li{
    font-size: 15px;
}
.DescriptionTextFullfilmentSection li{
    font-size: 9px;
}
.OrangeText{
    color: #DF4B0E;
    font-weight: bold;
}
.OrangeTextSize{
    color: #DF4B0E;
}
/* Footer section */
.FooterSection{
    display: flex;
    place-items: center;
    width: 100vw;
    height: 30vh;
    position: relative;
    text-align: left;
    font-size: 13px;
}
.FooterLogoContainer{
    position: absolute;
    top: 6vh;
    left: 10%;
    width: 20%;
}
.FooterLogo{
    margin-top: 0.6vh;
    width: 30%;
    transform: translateX(-15%);
}
.FooterInfoContainer{
    position: absolute;
    top: 6vh;
    left: 25%;
    width: 20%;
}
.SecondFooterInfoContainer{
    left: 40%;
}
.FooterInfoTitle{
    color: #E54B0E;
    font-weight: 600;
    align-items: center;
    min-height: 7vh;
    height: auto !important;
    height: 7vh;
    display: flex;
    align-items:center;
}
.FooterInfoDetail{
    display: flex;
    min-width: 400px;
    flex-direction: row;
    align-items: center;
}
.LandingFooterText {
    color: #404040;
    cursor: pointer;
}
@media (max-width: 1150px) {
    .MainContainer {
        width: 100vw;
    }
    .TitleWrapper{
        max-width: 70vw;
    }
    .MainTitle{
        font-size: 30px;
        line-height: 40px;
    }
    .MainSubTitle{
        font-size: 13px;
    }
    .LandingPageMainBtn{
        font-size: 13px;
    }
    .LandingPageImgContainer{
        max-width: 90%;
        width: 90%;
        display: flex;
        justify-content: center;
    }
    .LandingPageGif{
        max-width: 100%;
        width: 55vw;
        padding-top: 0vh;
        height: auto;
        display: flex;
    }
    .LandingPageFirstSection{
        height: 95vh;
        width: 100vw;
        display: flex;
        flex-direction: column;
    }
    .ComputerSectionTitleWrapper{
        position: absolute;
        top: 0;
        max-width: 45rem;
        text-align: center;
        padding-bottom: 0;
    }
    .IconsComputerSection{
        height: 180vh;
        margin-top: 10vh;
    }
    .LineIconsImgContainer{
        top: 10vh;
    }
    .ComputerImgContainer{
        width: 95%;
    }
    .SeparateIconsContainer {
        display: inline-flex;
        flex-direction: column;
        align-items: center;
    }
    .LineIconOneImg {
        display: flex;
        width: 40%;
        z-index: 999;
    }
    .LineIconOneText{
        margin-top: 0;
        z-index: 999;
        padding-bottom: 2vh;
    }
    .LineIconDotsRightImg{
        transform: rotate(95deg);
        width: 50%;
        margin-left: 7vw;
        overflow: hidden;
        padding: 2vh 0;
    }
    .LineIconDotsLeftImg{
        transform: rotate(265deg);
        width: 50%;
        margin-right: 7vw;
        overflow: hidden;
        padding: 2vh 0;
    }
    .LineIconsImg {
        display: none;
    }
    .TextInLineIcons {
        display: none;
    }
    .MapImgContainer{
        display:grid;
        flex-direction: column;
        width: 100vw;
        margin-top: 5vh;
    }
    .MapImg {
        width: 100%;
        height: auto;
        display: block;
        transform: translateY(-5%);
    }
    .FullfilmentSection {
        display: flex;
        flex-direction: column;
        place-items: center;
        width: 100vw;
        position: relative;
    }
    .TextFullfilmentSectionContainer{
        flex-direction: column;
        position: relative;
        width: 90%;
        margin: 0;
        max-width: 80%;
        text-align: left;
        padding: 0%;
        left: 0%;
        bottom: 0%;
        margin-bottom: 3vw;
        display: flex;
    }
    .NotMobileTextFullfilmentSection{
        display: none;
    }
    .MobileTextFullfilmentSection{
        display: inline-block;
    }
    .DescriptionTextFullfilmentSection{
        width: 100%;
    }
    .TextFullfilmentSectionContainer h1{
        font-weight: 700;
        font-size: 15px;
    }
    .TextFullfilmentSectionContainer li{
        font-size: 13px;
    }
    .FooterSection{
        flex-direction: column;
        display: flex;
        width: 100vw;
        height: 55vh;
        position: relative;
        font-size: 10px;
    }
    .FooterLogoContainer{
        top: 5vh;
        width: 80%;
        text-align: left;
    }
    .FooterInfoContainer{
        top: 23vh;
        width: 80%;
        left: 10%;
    }
    .SecondFooterInfoContainer{
        top: 39vh;
        padding-bottom: 5vh;
    }
    .FullfilmentSectionTitleWrapper{
        max-width: 70vw; 
    }
    .FooterLogo{
        width: 17%;
    }
    .FooterInfoDetail{
        min-width: 0;
    }
}
@media (max-width: 380px) {
    .IconsComputerSection{
        height: 195vh;
        top: 10vh;
        margin-bottom: 10vh;
    }
    .LandingPageImgContainer{
        max-width: 70%;
    }
}