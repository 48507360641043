.HomeTitle {
    font-weight:  bolder;
    font-size: 25px;
    margin-top: 5vh;
}
.NotionRenderContainer{
  align-self: center;
}
.NotionRender {
   overflow-y: scroll;
   height: 70vh;
   width: 70vw;
   scroll-behavior: smooth;
    /* Hide scrollbar for IE, Edge and Firefox */
   -ms-overflow-style: none;  /* IE and Edge */
   scrollbar-width: none;  /* Firefox */
}
/* Hide scrollbar for Chrome, Safari and Opera */
.NotionRender::-webkit-scrollbar {
    display: none;
}
.PageHeaderContainer{
    display: flex;
    flex-direction: row;
    margin-top: 5vh;
    flex-wrap: wrap;
    width: 75%;
    height: 25vh;
    justify-content: flex-start;
}
.PageHeader{
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.10);
    transition: box-shadow, transform, 0.2s ease-out;
    max-width: 18vw;
    min-height: 25vh;
    width: 15vw;
    height: 25vh;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    border-radius: 12px;
    margin-bottom: 3vh;
    margin-right: 3vh;
}
.PageHeader:hover {
    box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.10);
    transform: translateY(-5px);
    cursor: pointer;
}
.PageHeaderContent{
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 12px;
    color: white;
    display: flex;
    justify-content: center;
}
.PageHeaderText{
    font-weight: 800; 
    margin: 0;
    padding: 5vh;
    align-self: center;
    text-align: center;
}
.GoBackButtonContainer{
    display: flex;
    justify-content: center;
    margin-top: 0.5%;
}
.GoBackButton{
    background-color: rgba(229, 75, 14, 0.9);
    border-radius: 30px;
    font-size: 1.8vh;
    width: 10vw;
    height: 5vh;
    color: white;
    display: flex;
    cursor: pointer;
    border: none;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
    transition: box-shadow, transform, 0.2s ease-out;
}
.GoBackButton:hover {
    box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px);
    cursor: pointer;
}
.NotionGoBackText {
    color: white;
    font-weight: 500;
}
.LoadingContainer{
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: center;
    align-items: center;
}
.LoadingText{
    margin-top: 1vh;
}
.ContactContainer{
    display: flex;
    align-items: center;
}
.MailIcon{
    color: red;
    margin-right: 1.3vh;
}
.WhatsappIcon{
    color: green;
    margin-right: 1.3vh;
}
.StockPageExtraInfo {
    font-size: 10px;
    padding-left: 1vw;
    padding-right: 1vw;
}
.StockPageIframe{
    border: "none";
    border-style: "none";
    width: "100%";
    height: "84vh";
}
@media (max-width: 648px) {
    .PageHeaderContainer{
        display: grid;
        height: 15vh;
        justify-content: center;
    }
    .PageHeader{
        max-width: 80vw;
        min-height: 15vh;
        width: 90vw;
        height: 15vh;
        display: flex;
        flex-grow: 1;
        justify-content: center;
        margin-right: 0vh;
    }
    .PageHeaderContent{
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 12px;
        color: white;
        display: flex;
        justify-content: center;
    }
    .GoBackButton{
        width: 20vw;
    }
    .StockPageExtraInfo {
        font-size: 10px;
        padding-left: 1vw;
        padding-right: 1vw;
    }
    .AccordionContent.open {
        font-size: 10px;
    }
}
.ConfirmProductDialogContainer{
    overflow-y: scroll;
    height: 25vh;
    scroll-behavior: smooth;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}