.LogoContainer {
    width: 100%;
    display: flex;
    justify-content: center;
}
.NomadLogo {
    width: 13vw;
}
.NavigationButtons {
    margin-top: 1vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.TabHeader {
    width: 90%;
    border-bottom: 0.05vh solid white;
}
.TabHeaderActive {
    width: 90%;
    border-bottom: 0.05vh solid white;
    background-color: rgba(229, 75, 14, 0.3);
}
.TabHeader:hover {
    background-color: rgba(229, 75, 14, 0.3);
    border-radius: 5px 5px 0px 0px;
    cursor: pointer;
}
.IconContainer {
    border-bottom: 0.05vh solid white;
}
.IconContainerActive {
    border-bottom: 0.05vh solid white;
    background-color: rgba(229, 75, 14);
    border-radius: 5px 5px 0px 0px;
}
.IconContainer:hover {
    background-color: rgba(229, 75, 14, 0.3);
    border-radius: 5px 5px 0px 0px;
}
.IconLink {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.IconFilled{
    color: #ffffff;
    margin-right: 1.3vh;
    margin-left: 1.3vh;
}
.IconText {
  color: #ffffff;
}
.Footer{
    min-height: 2vh;
    font-size: 1.4vh;
    align-self: center;
    position: fixed;
    bottom: 1vh;
}
.MobileMenu {
    display: none;
}
/* Accordion component */
.AccordionTitle {
    max-width: 65%;
    color: "#fff";
}
.AccordionTitle.open {
    color: "#000000";
}
.IconAnimation.open{
    color: white;
    animation-duration: 0.3s;
    animation-name: iconAnimationOpen;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
}
.IconAnimation{
    color: white;
    animation-duration: 0.3s;
    animation-name: iconAnimation;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    position: absolute;
    right: 1vw;
}
.AccordionHeader {
    width: 90%;
    display: flex;
    align-items: center;
    border-bottom: 0.05vh solid white;
    cursor: pointer;
    position:relative;
}
.AccordionContent.open {
    display: flex;
    flex-direction: column;
    width: 90%;
    background-color: rgba(229, 75, 14, 0.3);;
    font-size: 13px;
}
.AccordionContent {
    display: none;
}
@media (max-width: 740px) {
    /*Mobile View */
    * {
        box-sizing: border-box;
    }
    .LogoContainer{
        align-items: center;
        justify-content: center;
        margin-right: 4vh;
    }
    .NomadLogo {
        width: 40%;
        right: 5%;
        position: relative;
    }
    .MobileMenu {
        display: flex;
        position: relative;
        right: 20%;
        font-size: 25px;
        margin: 2vh;
        cursor: pointer;
    }   
    .NavigationButtons {
        margin-top: 0px;
        padding-bottom: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        position: absolute;
        top: 11vh;
        left: -100%;
        transition: all 0.5s ease;
        flex-direction: column;
        list-style-type: none;
        grid-gap: 0px;
    }
    .IconAnimation.open{
        color: #1D1D1B;
    }
    .IconAnimation{
        color: #1D1D1B;
    }
    .AccordionContent.open {
        background-color: #e8e7e7;
    }
    .IconContainer:hover {
        background-color: #e8e7e7;
        border-radius: 5px 5px 0px 0px;
    }
    .NavigationButtons.active {
        background: #F1F1F1;
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
        align-content: center;
        padding-left: 0px;
    }
    .IconText {
        color: #1D1D1B;
    }
    .AccordionHeader p {
        color: #1D1D1B;
    }
    .IconFilled{
        color: #1D1D1B;
    }
    .Footer{
        display: none;
    }
}
@keyframes openAnimation {
    0% {
        opacity: 0;
        max-height: 55px; 
    }
    100% {
        opacity: 1;
        max-height: max-content;
    }
}
@keyframes closeAnimation {
    0% {
        opacity:0;
        max-height: 55px; 
    }
    100% {
        opacity: 1;
        max-height: 55px;
    }
}
@keyframes iconAnimation {
    0% {
        transform: rotate(0deg);
        color: "#ffff";
    }
    100% {
        transform:  rotate(0deg);
        color: "#fff";
    }
}
@keyframes iconAnimationOpen {
    0% {
        transform: rotate(0deg);
        color: "#ffff";
    }
    100% {
        transform: rotate(180deg);
        color: "#000000";
    }
}
@media (max-width:600px) {
    .AccordionItem {
        width: 80%;
    }
}
