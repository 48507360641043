.background {
  background-color: #b6b5b5;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.container {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
  height: 70%;
  margin-top: 120px;
  margin-bottom: 40px;
  align-items: center;
  justify-content: center;
}

.checkout-column{ 
  width: 50%;
  font-size: 20px;
}

.payment-column{ 
  width: 50%;
  font-size: 20px;
  padding-right: 15px;
  padding: 20px;
  background-color: #fff;
}

.title{
  text-align: center;
  font-weight: 600;
  font-size: 30px;
}


.details {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 758px;
  background-color: rgb(214, 129, 93);
  border-radius: 10px 0px 0px 10px;
  color: white;
  gap: 35px;
}
.product-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 70%;
  font-size: 18px;
  border-bottom: 1px solid white;
}
.product-title {
  display: flex;
  width: 80%;
}
.product-price {
  font-weight: 600;
}

.checkout-container {
  height: 758px !important;
  overflow-y: scroll;
  border-radius: 0px 10px 10px 10px;
}

.total{
  display: flex;
  flex-direction: column;
  width: 60%;
  margin-bottom: 20px;
  border-bottom: 1px solid white;
}

.product-info {
  display: flex;
  width: 100%;
  justify-content: space-between;

}

.logo {
  width: 20vw;
  border-radius: 50%;
  margin-left: 20px;
  margin-right: 20px;
}