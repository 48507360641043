.Nav {
    height: 11vh;
    display: flex;
    position: fixed;
    top: 0px;
    width: 100vw;
    overflow: hidden;
    background-color: white;
    justify-content: space-between;
    padding-left: 6vw;
    padding-right: 4vw;
    z-index: 9999;
    transition: 0.4s;
}
.NavBarContainer{
    display: flex;
}
.NavAtFirst{
    box-shadow: 0;
    background-color: #F1F1F1;
}
.NavWhenScroll{
    box-shadow: 0px 4px 16px rgba(0,0,0,0.1);
}
.NavBarLogo{
    display: flex;
    align-items: center;
}
.NavBarLogoImg{
    width: 12rem;
}
.NavLinks{
    display: flex;
    align-items: center;
}
.NavBtn{
    display: flex;
    align-items: center;
    margin-right: 5vw;
}
.NavLink{
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    padding-right: 1.5vw;
    font-size: 1vw;
    height: 100%;
    cursor: pointer;
    font-weight: 600;
    color: #E54B0E;
}
.NavLinkText{
    text-decoration: none;
    color:#404040;
}
.NavLinkText:hover{
    text-decoration: none;
    color: #E54B0E;
}

.NavLink:hover{
    color:#404040;
}
.NavLoginBtn{
    display: inline-flex;
    color: white;
    font-size: 0.8vw;
    align-items: center;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    font-weight: 600;
    background-color: #E54B0E;
    border-radius: 2rem;
    padding: 0.3rem 1rem;
    outline: none;
}
.NavLoginBtn:hover{
    opacity: .92;
    box-shadow: 1px 4px 3px 0 rgba(0,0,0,.24);
    background: #E54B0E radial-gradient(circle,transparent 1%,#E54B0E 0) 50%/15000%;
}
.MobileMenuNavBar {
    display: none;
}
.MobileNavigationBar {
    display: none;
}
/* ======= MEDIA QUERY FOR SMALLER SCREENS ======= */
@media screen and (max-width: 700px) {
    .Nav {
      width: 100%;
      padding: 0 0;
      justify-content: center;
    }
    .NavBarLogoImg {
      width: 30vw;
      position: absolute;
      left: 28vw;
    }
    .NavLink {
      font-size: 13px;
      margin-right: 1vw;
    }
    .NavLoginBtn{
      font-size: 12px;
      margin-right: 2vw;
    }
    .LandingMenuIcon{
        font-size: 5vw;
        background-color: transparent;
        padding: 1vh;
    }
    .NavBtn{
        margin-right: 0;
        right: 0;
        position: absolute;
        height: 100%;
    }
    .MobileMenuNavBar {
        display: flex;
        left: 0;
        position: absolute;
        height: 100%;
        cursor: pointer;
        align-items: center;
        margin-left: 5vw;
    }
    .NavBarContainer {
        justify-content: center;
    }
    .NavLinks {
        display: none;
    }
    .HideMobile{
        display: none;
    }
    .MobileNavigationBar {
        margin-top: 0px;
        padding-bottom: 10px;
        display: flex;
        flex-direction: column;
        width: 100%;
        position: fixed;
        top: 11vh;
        transform: translateX(-110%);
        transition: all 0.5s ease;
        overflow: visible;
        min-height: 30vh;
        box-shadow: 0px 4px 16px rgba(0,0,0,0.1);
    }
    .MobileNavigationBar.active {
        background: #FFFFFF;
        transition: all 0.5s ease;
        transform: translateX(0);
        z-index: 10000;
        padding: 16px 14px;
    }
    .NavLinkMobileNavBar{
        display: inline-flex;
        align-items: left;
        text-decoration: none;
        padding: 0 2vw;
        font-size: 15px;
        height: 100%;
        cursor: pointer;
        font-weight: 500;
        color:#404040;
        padding: 8px 16px;
        border-radius: 32px;
    }
    .NavLinkMobileNavBar a{
        color:#404040;
        text-decoration: none;
        outline: none;
    }
    .NavLinkMobileNavBar:active{
        background-color: rgba(229, 75, 14, 0.5);
    }
    .NavLinkMobileNavBar a:active{
        color:#ffffff;
    }
}