#DropFormFileUpload {
  height: 10vh;
  width: 100%;
  max-width: 100%;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

#DropInputFileUpload {
  display: none;
}

#DropLabelFileUpload {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: var(--border-upload);
  background-color: var(--background-upload);
  font-size: 14px;
}

#DropLabelFileUpload.DropDragActive {
  background-color: #ffffff;
}

.DropUploadButton {
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  background-color: transparent;
}

#DropDragFileElement {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}