.customSelect {
    display: flex;
    position: relative;
    height: 2.5em;
    font-size: 0.875em;
    border: var(--border-light) !important;
    margin: 0.5em 0;
    padding: 0.25em 1em;
    width: 180px;
    min-width: 150px;
    border-radius: 5px;
    height: 2.5em;
    cursor: pointer;
}

.customSelectSelectedValues {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.customSelect .closed {
    overflow: hidden;
}

.customSelectSelectedIcon {
    position: relative;
    left: 90px;
}

.customSelectPlaceholder {
    vertical-align: middle;
}

.customSelectOptions {
    position: absolute;
    top: calc(100% + 0.5vw);
    left: 0;
    border: 1px solid red;
    border-radius: 5px;
    border-color: var(--border-light) !important;
    background-color: white;
    display: block;
    padding: 0px 2px 2px;
    width: 160px;
    overflow-y: scroll;
    height: 30vh;
    scroll-behavior: smooth;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.customSelectOption {
    padding-bottom: 3px;
}

.customSelectOption:hover {
    background-color: var(--hover-light);
}

.customSelectOptions input[type=checkbox] {
    margin-right: 10px;
}

.clearAllButton {
    padding-inline: 0.5vw;
}