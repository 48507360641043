.SelectWrapper {
    position: relative;
}

.SelectWrapper select {
    appearance: none;
    border: var(--border-light);
    background-color: transparent;
    border-radius: 8px;
    padding: 0 1.5vw 0 0.5vw;
    width: 100%;
}

.SelectWrapper select:hover {
    cursor: pointer;
}

.SelectWrapper select:disabled {
    cursor :not-allowed;
}

.SelectIcon {
    fill: var(--color-text);
    position: absolute;
    right: 0.5vw;
    width: 0.5vw;
    height: 100%;
}

.SelectedItems {
    display: flex;
    flex-wrap: wrap;
    margin-top: 8px;
}

.SelectedItem {
    background-color: var(--color-primary);
    color: white;
    border-radius: 4px;
    padding: 4px 8px;
    margin-right: 8px;
    margin-bottom: 8px;
    cursor: pointer;
}

.SelectedItem:hover {
    background-color: darken(var(--color-primary), 10%);
}

.SelectWrapperLargeWidth {
    width: 380px;
}