.TrackingIndexView {
  width: 100%;
  margin-block-start: 15vh;
}

.TrackingOrderView {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.TrackingIndexContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.TrackingHeaderContainer {
  width: 30%;
  display: flex;
  justify-content: center;
}

.TrackingBodyContainer {
  width: 100%;
  background-color: lightgray;
  height: 100vh;
}

.TrackingBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  width: 100%;
  max-width: 700px;
  margin: 30px auto;
}

.TrackingLogoContainer {
  max-width: 480px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.TrackingFormContainer {
  padding-top: 40px;
  width: 70vw;
  max-width: 380px;
}

.TrackingSubtitle {
  font-weight: 600;
  margin: 0;
  color: black
}

.NomadTitleLogo {
  padding: 1rem;
  width: 70%;
}

.NomadHeaderLogo {
  width: 12rem;
  object-fit: cover;
  overflow: hidden;
}

.NomadHeaderLogo:hover {
  cursor: pointer;
}

.TrackingTextInput {
  width: 100%;
  height: 3.5vh;
  display: flex;
  align-content: center;
  align-items: center;
  margin-left: 0px;
  border: solid 1px rgba(161, 161, 161, 0.9);;
  border-radius: 6px;
}

.TrackingBodyRow {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.TrackingBodyRow.HeaderRow {
  align-items: center;
}

.TrackingBodyColumn {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 1rem;
  margin-bottom: 1rem;
}

.TimelineColumn {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 1rem 0;
}

.TrackingBodyData {
  margin-top: 1rem;
}

.TrackingBodyDataTitle {
  font-weight: 600;
  color: black
}

.SeparatorLine {
  width: 95%;
}

.TrackingPhotoLink {
  font-weight: 600;
  color: black;
  text-decoration: underline;
}

.Timeline .Body {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  position: relative;
}

.Timeline .Body .Line {
  position: absolute;
  width: 3px;
  margin-left: 6px;
  height: 60%;
  margin-bottom: 1.5vh;
  background-color: var(--color-primary);
  border-radius: 15px;
  z-index: 0;
}

.Timeline .Body .Item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0;
}

.Timeline .Body .Item .Dot {
  background-color: var(--color-secondary);
  border: 3px solid var(--color-primary);
  border-radius: 50%;
  z-index: 90;
  padding: 5px;
}

.Timeline .Body .Item .Dot.Small {
  margin: 0 2px;
  padding: 3px;
}

.Timeline .Body .Item .Dot.Fill {
  background-color: var(--color-primary);
}

.Timeline .DotLabel {
  margin: 5px;
  font-size: 85%;
}

.Timeline .DotLabel.Small {
  font-size: 75%;
}

.MapContainer {
  width: 90%;
  height: 100%;
}

.TrackingBodyRow.MapRow {
  align-items: center;
}

.MapRow {
  justify-content: center;
  height: 30vh;
}

.HelpSection {
  margin-top: 20px;
}
.HelpContent {
  margin-top: 10px;
  font-size: 0.9em;
  color: gray;
}

.HelpText {
  color: black;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 20px;
}
